<template>
    <div class="input-group" :class="{ 'input-group--error': error }">
        <div class="input-group__label" v-if="label">
            {{ isRequired ? label + " *" : label }}
        </div>
        <div class="input-group__field input-date">
            <input
                data-inputmask-alias="datetime"
                data-inputmask-inputformat="dd.mm.yyyy"
                inputmode="numeric"
                ref="input"
                class="input"
                type="text"
                :required="isRequired"
                :disabled="isDisabled"
                :value="currentDate"
                @change="onChange($event.target.value)"
            />
        </div>
        <div class="input-group__message" v-if="error">
            {{ typeof error === 'string' ? error : error[0] }}
        </div>
    </div>
</template>

<script>
import AirDatepicker from 'air-datepicker';
import InputMask from "inputmask";

export default {

    props: {
        label: String,
        error: [String, Array],
        code: String,
        isRequired: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        modelValue: String,
        minDate: String,
        maxDate: String,
    },

    data() {
        return {
            inputMask: "99.99.9999",
            datepicker: null,
        };
    },

    computed: {
        currentDate() {
            return this.modelValue && this.modelValue.split('-').reverse().join('.')
        }
    },

    methods: {
        reversedDateToDash(date) {
            return date && date.split('.').reverse().join('-')
        },
        addInputMask(selector) {
            InputMask(this.inputMask).mask(selector);
        },
        deleteInputMask(selector) {
            selector.inputmask.remove();
        },
        onChange(date) {
            const verifiedDate = this.verifyDate(date)
            this.$emit('update:modelValue', this.reversedDateToDash(verifiedDate))
        },
        verifyDate(date) {
            if (!(new Date(date) >= new Date()) || (new Date(date).toLocaleDateString() === new Date().toLocaleDateString())) {
                this.datepicker.selectDate(this.minDate)
                if (this.minDate) return this.minDate
                else return date
            } else return date
        }
    },

    mounted() {
        this.addInputMask(this.$refs.input);

        const startDate = this.modelValue ? this.modelValue : ''
        this.datepicker = new AirDatepicker(this.$refs.input, {
            startDate,
            selectedDates: [ startDate ],
            minDate: this.minDate,
            maxDate: this.maxDate,
            onSelect: date => {
                this.$emit('update:modelValue', this.reversedDateToDash(date.formattedDate))
            }
        })
    },

    watch: {
        minDate(value) {
            this.datepicker.update({ minDate: value })
        }
    }

};
</script>
