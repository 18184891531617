<template>
    <div
        class="input-group__result result-list"
        v-if="isShowList"
        ref="resultWrapper"
        data-simplebar
    >
        <div
            class="result-list__item"
            v-for="item in suggestions" :key="item.value"
            @click.prevent="onSelect(item)"
        >
            {{ item.value }}
        </div>
    </div>
</template>

<script>
import "simplebar-vue/dist/simplebar-vue.js";

export default {
    // data() {
    //     return {
    //         isShow: true
    //     }
    // },

    computed: {
        suggestions() {
            return this.$store.state.dadata.suggestions
        },
        selectedSuggestion() {
            return this.$store.state.dadata.selectedSuggestion
        },
        isShowList() {
            return !!this.suggestions.length && !this.selectedSuggestion
        }
    },

    methods: {
        onSelect(item) {
            this.$store.commit("DADATA_GET_SELECTED_SUGGESTION", item)
        }
    }

    // methods: {
    //     closeClickNotCurrentArea(event) {
    //         if (!this.isShow) return

    //         const area = this.$refs.resultWrapper
    //         if (!area) return

    //         const target = event.target
    //         const itSelectArea =
    //             target === area || area.contains(target)

    //         if (!itSelectArea) this.isShow = false
    //     }
    // },

    // mounted() {
    //     document.addEventListener('click', this.closeClickNotCurrentArea, false)
    // },
    // beforeUnmount() {
    //     document.removeEventListener('click', this.closeClickNotCurrentArea, false)
    // },

}
</script>
