<template>
    <div class="input-group" :class="{ 'input-group--error': error }">
        <div class="input-group__label" v-if="label">
            {{ isRequired ? label + " *" : label }}

            <div class="info" v-if="tooltip">
                <svg class="icon icon-Info ">
                    <use xlink:href="#Info"></use>
                </svg>
                <div class="tooltip">
                    <div class="tooltip__title strong">
                        {{ tooltipObj.header }}
                    </div>
                    <div class="tooltip__text" v-html="tooltipObj.body"></div>
                </div>
            </div>

            <slot name="addition" />
        </div>
        <div class="input-group__field">
            <input
                ref="input"
                class="input"
                :type="type"
                :placeholder="placeholder"
                :required="isRequired"
                :disabled="isDisabled"
                v-model="inputValue"
                @input="onChange($event.target.value)"
                @paste="onPaste"
            />
        </div>
        <div class="input-group__message input-group__error" v-if="error">
            {{ typeof error === 'string' ? error : error[0] }}
        </div>
        <ResultList v-if="code === 'inn'" />
        <slot />
    </div>
</template>

<script>
import InputMask from "inputmask";
import ResultList from "@/components/form/result-list"

export default {
    components: {
        ResultList
    },
    props: {
        type: String,
        label: String,
        placeholder: String,
        error: [String, Array],
        code: String,
        isRequired: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: String,
            default: null,
        },
        tooltip: String,
        newValue: {
            type: String,
            default: null,
        },
        maxLength: Number,
    },

    data() {
        return {
            inputMask: "",
            inputValue: this.modelValue,
        };
    },

    computed: {
        tooltipObj() {
            return JSON.parse(this.tooltip);
        },

        selectedSuggestion() {
            return this.$store.state.dadata.selectedSuggestion
        },
    },

    methods: {
        onPaste(event) {
            setTimeout(() => {
                this.onChange(event.target.value)
            }, 100)
        },
        addInputMask(selector) {
            InputMask(this.inputMask).mask(selector);
        },
        deleteInputMask(selector) {
            selector.inputmask.remove();
        },
        updateValue(value) {
            this.$emit('update:modelValue', value)
        },
        onChange(value) {
            let query = value

            if (this.maxLength) {
                if (query.length > this.maxLength) query = query.slice(0, this.maxLength)
            }
            this.updateValue(query)
            this.inputValue = query

            if (this.code === 'inn')
                if (query.length >= 10)
                    this.$store.dispatch("DADATA_GET_INFO", { query: query })
                else {
                    this.$store.commit("DADATA_SET_SUGGESTIONS", [])
                    this.$store.commit("DADATA_GET_SELECTED_SUGGESTION", null)
                }
        },

        assignInputMask() {
            if (this.type === "tel") {
                this.inputMask = "+7 (999) 999-99-99";
                this.addInputMask(this.$refs.input);
            }

            if (this.code === "snils") {
                this.inputMask = "999-999-999 99";
                this.addInputMask(this.$refs.input);
            }
        }
    },

    watch: {
        modelValue(newValue) {
            this.inputValue = newValue
        },
        type(newType) {
            if (newType === "tel") this.addInputMask(this.$refs.input);
            else if (this.$refs.input.inputmask)
                this.deleteInputMask(this.$refs.input);
        },
        selectedSuggestion() {
            if (this.selectedSuggestion)
                switch (this.code) {
                    case "full_name":
                        this.inputValue = this.selectedSuggestion.data.name.full_with_opf
                        this.updateValue(this.inputValue)
                        break;
                    case "short_name":
                        this.inputValue = this.selectedSuggestion.data.name.short_with_opf
                        this.updateValue(this.inputValue)
                        break;
                    case "ogrn":
                        this.inputValue = this.selectedSuggestion.data.ogrn
                        this.updateValue(this.inputValue)
                        break;
                    case "kpp":
                        this.inputValue = this.selectedSuggestion.data.kpp
                        this.updateValue(this.inputValue)
                        break;
                    case "address":
                        this.inputValue = this.selectedSuggestion.data.address.unrestricted_value
                        this.updateValue(this.inputValue)
                        break;
                }
        },
        newValue(value) {
            if (value) this.inputValue = value
            else this.inputValue = null
        }
    },

    mounted() {
        this.assignInputMask()
    },

    updated() {
        this.assignInputMask()
    }
};
</script>
