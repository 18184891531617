<template>
    <div class="input-group" :class="{ 'input-group--error': error }">
        <div class="input-group__label" v-if="label">
            {{ isRequired ? label + " *" : label }}

            <div class="info" v-if="tooltip">
                <svg class="icon icon-Info ">
                    <use xlink:href="#Info"></use>
                </svg>
                <div class="tooltip">
                    <div class="tooltip__title strong">
                        {{ tooltipObj.header }}
                    </div>
                    <div class="tooltip__text" v-html="tooltipObj.body"></div>
                </div>
            </div>
        </div>
        <div class="input-group__field">
            <label class="btn input btn-download">
                <template v-if="!file">
                    <svg class="icon icon-arrow-down ">
                        <use xlink:href="#arrow-down"></use>
                    </svg>
                    Загрузить документ
                    <input
                        type="file"
                        :required="isRequired"
                        @change="saveFile"
                        hidden
                    />
                </template>
                <template v-else>{{ file }}</template>
            </label>

        </div>
        <div class="input-group__message" v-if="error">
            {{ typeof error === 'string' ? error : error[0] }}
        </div>
    </div>
</template>

<script>
import { ref, watch, computed } from "vue";

export default {
    props: {
        label: String,
        error: [String, Array],
        code: String,
        isRequired: {
            type: Boolean,
            default: false
        },
        tooltip: String,
        modelValue: [String, FormData],
    },

    setup(props, { emit }) {
        const file = ref(null);
        const saveFile = event => {
            file.value = event.target.files[0].name;

            const formData = new FormData();
            formData.append(props.code, event.target.files[0]);
            emit("update:modelValue", formData);
        };

        watch(() => props.modelValue, () => {
            if (props.modelValue)
                file.value = 'Загружен';
        })

        return {
            saveFile,
            file,
            tooltipObj: computed(() => JSON.parse(props.tooltip)),
        };
    }
};
</script>
